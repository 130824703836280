import React, { useContext, useEffect, useState } from "react";
import Sides from "../components/_main/Sides/Sides";
import { getSides } from "../services";
import CartFunction from "../components/cart";
import GlobalContext from "../context/GlobalContext";
import CartList from "../components/_main/Cart/CartList";
import OrderSummary from "../components/_main/Cart/OrderSummary";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function SidesMenu() {
  const [sidesData, setSideData] = useState();
  const [reset, setReset] = useState(false);
  const cartFn = new CartFunction();

  const globalctx = useContext(GlobalContext);
  const [cart, setCart] = globalctx.cart;
  const [payloadEdit, setPayloadEdit] = globalctx.productEdit;
  const [isAuthenticated, setIsAuthenticated] = globalctx.auth;

  const { user } = useSelector((state) => state);

  const location = useLocation();
  const navigate = useNavigate();

  const sides = async () => {
    await getSides()
      .then((res) => {
        setSideData(res.data);
      })
      .catch((err) => {
        console.log("Error From Get Sides :", err);
      });
  };

  // Handle Place Order
  const handlePlaceOrder = async () => {
    if (cart?.product?.length > 0) {
      if (isAuthenticated && user !== null) {
        navigate("/checkout-page");
      } else {
        localStorage.setItem("redirectTo", location?.pathname);
        navigate("/login-registration");
      }
    } else {
      toast.error("Cart is Empty...");
    }
  };

  // Reset Controls
  const resetControls = () => {
    // Reset All Fields
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 200);
  };

  useEffect(() => {
    sides();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-9 col-lg-9 row gx-4 mt-3 mb-3">
          {sidesData?.map((data) => {
            return <Sides data={data} key={data.sideCode} cartFn={cartFn} />;
          })}
        </div>
        {/* Cart List */}
        <div className="col-xl-3 col-lg-3 mt-3 d-lg-block d-none">
          <div className="cartlist w-100 text-start">
            <h2 className="p-3 text-center orderTitle">Your Orders</h2>
            {cart?.product.map((cData) => {
              return (
                <CartList
                  cData={cData}
                  key={cData.id}
                  setPayloadEdit={setPayloadEdit}
                  payloadEdit={payloadEdit}
                  resetControls={resetControls}
                  setLoading={false}
                />
              );
            })}
          </div>
          {/* Place Order */}
          <div className="placeorder w-100 mt-5">
            <OrderSummary cart={cart} />
            <div className="placeOrderBtn w-100 mt-3">
              <button
                className="btn btn-md w-100 py-2 btn-pills"
                onClick={handlePlaceOrder}
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SidesMenu;
