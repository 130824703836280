import React, { useEffect } from "react";
import Header from "../components/_main/Header";
import Footer from "../components/_main/Footer";
import bgImage from "../assets/images/bg-img.jpg";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />

      <section className="container-fluid new-block m-0 p-0 w-100">
        <div className="nav-margin"></div>
        {/* Heading */}
        <div className=" custmized-main">
          <div className="d-flex flex-wrap justify-content-center bg-dark align-items-center p-3 custmized">
            <h2 className="m-3 text-white">
              <strong>About Us</strong>
            </h2>
          </div>
        </div>

        <section className="about-us-block new-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-custom1 pd0">
                <div className="img-holder">
                  <img src={bgImage} alt="" className="img-responsive" />
                </div>
              </div>
              <div className="col-custom2 pd0">
                <div className="fixed-bg">
                  <img src={bgImage} alt="" className="img-responsive" />
                </div>
                <div className="block-stl12">
                  <p className="fst-italic lh-base infoText">
                    At Mr Singh Pizza, we're passionate about serving delicious,
                    100% vegetarian pizzas combining the flavors of India with
                    the love for Italian cuisine to satisfy your cravings and
                    bring joy to your taste buds. From classic favorites to
                    innovative creations, we're dedicated to delivering the best
                    pizza experience possible.
                  </p>
                  <br />
                  <br />
                  <p className="fst-italic lh-base infoText">
                    What started as a small, pizzeria in Brampton has now grown
                    into a beloved favorite, known for our mouthwatering pizzas
                    with stores across southern Ontario.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </section>

        <section className="our-history new-block">
          <div
            className="fixed-bg parallax"
            style={{ background: `url(${bgImage})` }}
          ></div>
          <div className="overlay"></div>
          <div className="container-fluid pd0">
            <div className="row">
              <div className="col-lg-12">
                <div className="title">
                  <p className="top-h">Satisfaction with every bite</p>
                  <h2>Our Commitments</h2>
                  <div className="btm-style">
                    <span></span>
                  </div>
                  <div className="container p-2">
                    <div className="row gx-3 pt-4 justify-content-center">
                      <div class="col-lg-10 col-md-12 col-sm-12 col-xs-6">
                        <div class="block-stl14">
                          <div class="img-holder p-1 fst-italic">
                            <p className="commitText lh-base">
                              <strong className="top-h">Quality - </strong>
                              <span className="text-white  ">
                                Quality is at the heart of everything we do at
                                Mr Singh Pizza. We source the freshest produce,
                                sauces, and finest cheeses to ensure that every
                                pizza we serve is of the highest quality.
                              </span>
                            </p>
                          </div>
                          {/* <h5 className="text-white">Quality</h5> */}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-3  justify-content-center">
                      <div class="col-lg-10 col-md-12 col-sm-12 col-xs-6">
                        <div class="block-stl14">
                          <div class="img-holder p-1 fst-italic">
                            <p className="commitText lh-base">
                              <strong className="top-h">Fresh - </strong>
                              <span className="text-white">
                                Our dough is made fresh daily, and our sauces
                                are prepared in-house to guarantee that every
                                bite is bursting with flavor.
                              </span>
                            </p>
                          </div>
                          {/* <h5 className="text-white">Fresh</h5> */}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-3  justify-content-center">
                      <div class="col-lg-10 col-md-12 col-sm-12 col-xs-6">
                        <div class="block-stl14">
                          <div class="img-holder p-1 fst-italic">
                            <p className="commitText lh-base">
                              <strong className="top-h">
                                100% Vegetarian -{" "}
                              </strong>
                              <span className="text-white">
                                We believe that eating vegetarian shouldn't mean
                                sacrificing flavor or satisfaction. From 50+
                                fresh vegetable toppings to our rich and creamy
                                plant-based cheeses, every pizza is a
                                celebration of the vibrant flavors. Whether
                                you're a lifelong vegetarian or just looking to
                                add more plant-based options to your diet, we've
                                got something for everyone to enjoy.
                              </span>
                            </p>
                          </div>
                          {/* <h5 className="text-white">100% Vegetarian </h5> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Footer />
    </>
  );
}

export default AboutUs;
