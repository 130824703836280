import React, { useContext, useEffect, useState } from "react";
import Header from "../components/_main/Header";
import Footer from "../components/_main/Footer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import GlobalContext from "../context/GlobalContext";
import { toast } from "react-toastify";
import { sendContactUsEmail } from "../services";
import bgImage from "../assets/images/ptrn1.jpg";

// Validation Functions
const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str}`;
};
const canadianPhoneNumberRegExp = /^\d{3}\d{3}\d{4}$/;
const canadianPostalCode = Yup.string().test(
  "is-canadian-postal-code",
  "Invalid Canadian Postal Code",
  (value) => {
    if (!value) return true;
    const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
    return postalCodeRegex.test(value);
  }
);

const ValidateSchema = Yup.object({
  firstname: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z\ ]+$/,
      "First name should only contain alphabetic characters, spaces"
    )
    .min(3, "First name must be at least 3 characters")
    .max(50, "First name cannot be longer than 50 characters"),
  lastname: Yup.string()
    .required("Last name is required")
    .matches(
      /^[A-Za-z\ ]+$/,
      "Last name should only contain alphabetic characters, spaces"
    )
    .min(3, "Last name must be at least 3 characters")
    .max(50, "Last name cannot be longer than 50 characters"),
  phoneno: Yup.string()
    .required("Phone number is required")
    .matches(
      canadianPhoneNumberRegExp,
      "Invalid Canadian phone number format."
    ),
  email: Yup.string()
    .email("Invalid email address...")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid Email Address"
    )
    .required("Email is required"),
  message: Yup.string()
    .required("Message is required")
    .min(3, "Message must be at least 3 characters")
    .max(800, "Message cannot be longer than 800 characters"),
});

function ContactUs() {
  // Global Context
  const globalctx = useContext(GlobalContext);
  const [user, setUser] = globalctx.user;
  const [isAuthenticated, setIsAuthenticated] = globalctx.auth;
  const [regUser, setRegUser] = globalctx.regUser;

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const onSubmit = async (values, { resetForm }) => {
    let payload = {
      firstName: values.firstname,
      lastName: values.lastname,
      mobileNumber: values.phoneno,
      email: values.email,
      message: values.message,
    };
    setLoading(true);
    await sendContactUsEmail(payload)
      .then((res) => {
        toast.success(res.message);
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 500) {
          toast.error(err.response.data.message);
        }
        setLoading(false);
      });
  };
  // Use Formik
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      phoneno: "",
      email: "",
      message: "",
    },
    validateOnBlur: true,
    validationSchema: ValidateSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="container-fluid new-block m-0 p-0 w-100">
        <div className="nav-margin"></div>
        {/* Heading */}
        <div className="custmized-main">
          <div className="d-flex flex-wrap justify-content-center bg-dark align-items-center p-3 custmized">
            <h2 className="m-3 text-white">
              <strong>Contact Us</strong>
            </h2>
          </div>
        </div>
        <div class="center-wrapper cn-us new-block">
          <div
            class="fixed-bg parallax"
            style={{ background: `url(${bgImage})` }}
          ></div>
          <div class="overlay"></div>
          <div class="form-block1">
            <div className="container">
              <div className="row gx-3 ">
                <div class="clearfix"></div>
                {/* <div className="content col-xl-5 col-lg-7 col-md-12 col-sm-12 rounded px-lg-4 px-md-5 px-sm-1 py-4 "> */}
                <form className="w-100" onSubmit={formik.handleSubmit}>
                  <div className="row gx-3">
                    {/* FirstName */}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        className="form-control mb-3"
                        type="text"
                        name="firstname"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="First Name"
                      />
                      {formik.touched.firstname && formik.errors.firstname ? (
                        <div className="text-danger formErrMsg mt-2 mb-3">
                          {formik.errors.firstname}
                        </div>
                      ) : null}
                    </div>

                    {/* LastName */}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        className="form-control mb-3"
                        type="text"
                        name="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Last Name"
                      />
                      {formik.touched.lastname && formik.errors.lastname ? (
                        <div className="text-danger formErrMsg mt-2 mb-3">
                          {formik.errors.lastname}
                        </div>
                      ) : null}
                    </div>
                    {/* Phone Number */}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        className=" form-control mb-3"
                        type="tel"
                        name="phoneno"
                        value={formik.values.phoneno}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Mobile Number"
                      />
                      {formik.touched.phoneno && formik.errors.phoneno ? (
                        <div className="text-danger formErrMsg mt-2 mb-3">
                          {formik.errors.phoneno}
                        </div>
                      ) : null}
                    </div>

                    {/* Email */}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <input
                        className=" form-control mb-3"
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Email"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger formErrMsg mt-2 mb-3">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>

                    {/* Message */}
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <textarea
                        className=" form-control mb-3"
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        rows={4}
                        placeholder="Message"
                      ></textarea>
                    </div>
                    {formik.touched.message && formik.errors.message ? (
                      <div className="text-danger formErrMsg mt-2 mb-3">
                        {formik.errors.message}
                      </div>
                    ) : null}

                    <div class="col-lg-12 col-md-12 text-center ">
                      <button type="submit" className="btn btn3 regBtn py-2">
                        Send Message
                      </button>
                    </div>
                    {/* <div className="col-lg-12 col-md-12 col-sm-12">
                      <button
                        className=" py-2 fw-bold btn btn-md regBtn"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div> */}
                  </div>
                </form>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default ContactUs;
