import React from "react";
import { Link } from "react-router-dom";

function SPNotFound() {
  return (
    <div className="d-flex w-100 vh-100 justify-content-center align-items-center flex-column">
      <p className="mb-3">Page Not Found</p>
      <Link to={"/"} className="btn btn-sm btn-dark">
        Back
      </Link>
    </div>
  );
}

export default SPNotFound;
