import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram, FiYoutube } from "react-icons/fi";
import { getStoreLocation } from "../../services";
import { toast } from "react-toastify";

const Footer = () => {
  const [storeDetails, setStoreDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  // API - Get Store Location
  const getStoreDetails = async () => {
    setLoading(true);
    await getStoreLocation()
      .then((res) => {
        setLoading(false);
        setStoreDetails(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 400 || err.response.status === 500) {
          toast.error(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    getStoreDetails();
  }, []);

  return (
    <>
      {/* <footer className="main-footer new-block">
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12">
              <div className="footer-head">
                <h3>About Us :</h3>
              </div>
              <div className="footer-content">
                <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  Welcome to MrSinghs Pizza, where passion for great taste meets
                  the joy of a healthier choice. We believe that delicious food
                  doesn't have to compromise your values or well-being. we are
                  committed to crafting the finest vegetarian pizzas that not
                  only tantalize your taste buds but also nourish your body with
                  the goodness of nature.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
              <div className="footer-head">
                <h3>Contact Us :</h3>
              </div>
              <div className="footer-content">
                <ul className="list-unstyled">
                  <li>
                    <Link to="mailto:contact@mrsinghspizza.com?subject=Contact">
                      contact@mrsinghspizza.com
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:+19055004000">+1 905-500-4000</Link>
                  </li>

                  {storeDetails?.map((data) => {
                    return (
                      <li key={data.code}>
                        <p className="text-white mb-2">
                          <strong> {data.storeLocation} : </strong>
                        </p>
                        <Link>{data.storeAddress}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer> */}

      <div className="copy-right">
        <div className="container">
          <p>
            <Link to="#">Mr. Singhs Pizza</Link> - All Rights Reserved Design
          </p>
          <ul className="social-nav">
            {/* <li>
              <Link to="#">
                <FiTwitter />
              </Link>
            </li> */}
            <li>
              <Link
                target="_blank"
                to="https://www.instagram.com/mrsinghspizzacanada/?next=https%3A%2F%2Fwww.instagram.com%2Fdirect%2Finbox%2F%3F__coig_login%3D1"
              >
                <FiInstagram />
              </Link>
            </li>
            {/* <li>
              <Link to="#">
                <FiFacebook />
              </Link>
            </li> */}
            {/* <li>
              <Link to="#">
                <FiYoutube />
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
